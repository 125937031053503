import React, { useContext, useEffect, useState } from "react"
import { Grid} from "@mui/material";
import { setChecksTitle, setOperationState } from "../../store/reducer";
import { getManagementInfo } from "../../helpers/startOperation";
import { IOperationsStatus } from "../../types/FaceVerification";
import { initialFvParameters, useLocalStorage } from "../../helpers/useLocalStorage";
import { FVInitParams } from "neurotec-faceverification-management-client";
import { fvInitParamsStorage } from "../../config/const";
import { AppContext } from "../../store/context";
import { RefVideoStream } from "../VideoStream/BiometricFaceStream";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import OperationContainer from "../../components/Operations/styled/OperationContainer";
import OperationTitle from "../../components/Operations/styled/OperationTitle";
import { ModalityType } from "../Enroll/Enroll";
import FaceOperation from "../Enroll/Modalities/FaceOperation";
import Overview from "../Overview/Overview";
import CheckSideBar from "./components/CheckSideBar";
import { ModalityStateStatus } from "../../store/actions";

const Checks: React.FC = () => {

    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const { enqueueSnackbar } = useSnackbar();
    const [activeModality, setActiveModality] = useState<ModalityType | null>(null);
    const {state, dispatch} = useContext(AppContext)
    const history = useHistory();
    const [checkState, setCheckState] = useState<IOperationsStatus>(IOperationsStatus.IDLE);
    const childRef = React.useRef<RefVideoStream>(null)
    const [faceOperationUUID, setFaceOperationUUID] = useState("");

    useEffect(() => {
        const selectName = (livenessMode: boolean, icao: boolean) => {
            if (livenessMode) {
                if (icao) {
                    dispatch(setChecksTitle("Face Check Liveness & ICAO"))
                } else {
                    dispatch(setChecksTitle("Face Check Liveness"))
                }
            } else {
                if (icao)
                    dispatch(setChecksTitle("Face Check ICAO"))
                else {
                    dispatch(setChecksTitle("Face Checks Liveness & ICAO"))
                }
            }
        }

        const fetchName = async () => {
            try {
                const managementInfo = await getManagementInfo();
                if (!managementInfo) {
                    enqueueSnackbar("Could not connect to the server", {variant: "error"})
                    return;
                }
                if (managementInfo.allowSettingsOverride) {       
                    if (options.livenessMode !== undefined && options.checkIcaoCompliance !== undefined)
                        selectName(options.livenessMode > 0, options.checkIcaoCompliance)
                } else {
                    selectName(managementInfo.livenessMode > 0, managementInfo.checkIcaoCompliance)
                }
            } catch (error) {
                console.log(error)
            }
        }

        fetchName()
    }, [dispatch, options, enqueueSnackbar])

    useEffect(() => {
        if (state.activeModalities.faceModality) {
            setActiveModality(prev => {
                if (prev == null) {
                    return ModalityType.FACE_MODALITY
                } else {
                    return prev
                }
            })
        }
    }, [state.activeModalities.faceModality, history])

    useEffect(() => {
        if (state.activeModalities.faceModality === false) {
            history.push("/")
        }
    })

    const getName = (): string => {
        switch (activeModality) {
            case ModalityType.FACE_MODALITY:
                return state.checksTitle
            case ModalityType.OVERVIEW:
                return "Overview"
            default: 
                return state.checksTitle
        }
    }

    const getNextModality = () => {
        let index = 0
        const operationState = state.operationState.modalityState.map((s, i) => {
            if (s.state === ModalityStateStatus.ACTIVE) {
                index = i + 1;
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.DONE
                }
            }
            if (i === index) {
                return {
                    modality: s.modality,
                    state: ModalityStateStatus.ACTIVE
                }
            }
            return s
        })
        dispatch(setOperationState({subjectId: state.operationState.subjectId, modalityState: operationState}))
        if (activeModality === ModalityType.FACE_MODALITY) {
            setActiveModality(ModalityType.OVERVIEW)
            setCheckState(IOperationsStatus.IDLE)
        } 
        if (activeModality === ModalityType.VOICE_MODALITY) {
            setActiveModality(ModalityType.OVERVIEW)
            setCheckState(IOperationsStatus.IDLE)
        }
    }

    const getActiveState = () => {
        switch (activeModality) {
            case ModalityType.FACE_MODALITY:
                return (
                    <FaceOperation
                        setCheckState={setCheckState}
                        childRef={childRef}
                        setOperationUUID={setFaceOperationUUID}
                    />
                )
            case ModalityType.OVERVIEW:
                return  (
                    <Overview
                        faceOperationUUID={faceOperationUUID}
                        voiceOperationUUID={""}
                    />
                )
        }
    }

    return (
        <OperationContainer maxWidth={false}>
            <Grid container style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <OperationTitle>
                    {getName()}
                </OperationTitle>
                {getActiveState()}
                <CheckSideBar
                    checkState={checkState}
                    setCheckState={setCheckState}
                    activeModality={activeModality}
                    childRef={childRef}
                    getNextModality={getNextModality}
                />
            </Grid>
        </OperationContainer>
    );
}

export default Checks;



