import { FVInitParams } from "neurotec-faceverification-management-client";
import { ManagementAPI } from "../config/management-api";
import { IManagementInfo } from "../types/FaceVerification";

export const getManagementInfo = async () => {
    try {
        const res = await ManagementAPI.getManagementInfo();
        if (res.status === 200) {
            let response = JSON.stringify(res.data);
            return (JSON.parse(response) as IManagementInfo);
        }
        return null;
    } catch (error) {
        return null
    }
}

export const getInitParamsByManagementConfig = (managementInfo: IManagementInfo, fvInitParams: FVInitParams) => {
    let initParams: FVInitParams = {};
    if (managementInfo && managementInfo.allowSettingsOverride) {
        initParams = fvInitParams;
        initParams.advancedParameters = initParams.advancedParameters?.filter(x => x.key && x.value);
    } else {
        initParams.livenessMode = managementInfo.livenessMode
        initParams.checkIcaoCompliance = managementInfo.checkIcaoCompliance
    }
    return initParams;
}