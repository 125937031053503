import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextCell from "./TextCell";
import { makeStyles } from "@mui/styles";
import ISOConfirmationDialog, { parameter } from "./ISOConfirmationDialog";
import { useState } from "react";
import { FVAdvancedInitParams, FVInitParams } from "neurotec-faceverification-management-client";
import { LivenessMode } from "../../../types/FaceVerification";
import { settingUseStyles } from "../Settings";


const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    cell: {

    },
    cellBold: {
        fontWeight: 500
    },
    rowHead: {
        marginLeft: "1rem",
        backgroundColor: "#B6D3D3"
    },
    rowOdd: {
        backgroundColor: "#f4f8f5"
    },
    rowEven: {

    },
    button: {
        opacity: "100%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
    disabledButton: {
        opacity: "50%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
}))

interface IISOLivenessTable {
    options: FVInitParams
    setOptions: React.Dispatch<React.SetStateAction<FVInitParams>>
    setQualityImageSize: React.Dispatch<React.SetStateAction<[number, number]>>
    active: boolean
}

const ISOLivenessTable: React.FC<IISOLivenessTable> = (props) => {

    const classes = useStyles();
    const classesContainer = settingUseStyles()
    const [open, setOpen] = useState(false)
    
    const handleApply = () => {
        setOpen(true)
    }

    const findOption = (option: string): FVAdvancedInitParams | undefined => {
        return props.options.advancedParameters?.find((el) => el.key === option)
    }

    const handlePcSettings = () => {
        let threshold = findOption("LivenessThreshold")
        if (threshold) {
            threshold.value = "70"
        } else {
            threshold = {key: "LivenessThreshold", value: "70"}
            props.options.advancedParameters?.push(threshold)
        }

        let livenessQuality = findOption("PassiveLivenessQualityThreshold")
        if (livenessQuality) {
            livenessQuality.value = "60"
        } else {
            livenessQuality = {key: "PassiveLivenessQualityThreshold", value: "60"}
            props.options.advancedParameters?.push(livenessQuality)
        }

        let livenessSensitivity = findOption("PassiveLivenessSensitivityThreshold")
        if (livenessSensitivity) {
            livenessSensitivity.value = "30"
        } else {
            livenessSensitivity = {key: "PassiveLivenessSensitivityThreshold", value: "30"}
            props.options.advancedParameters?.push(livenessSensitivity)
        }

        let imageSize = findOption("StrictQualityImageSize")
        if (imageSize) {
            imageSize.value = "921600"
        } else {
            imageSize = {key: "StrictQualityImageSize", value: "921600"}
            props.options.advancedParameters?.push(imageSize)
        }
        props.setQualityImageSize([1280, 720])
        props.setOptions({...props.options, livenessMode: LivenessMode.PASSIVE})
    }

    const handleMobileSettings = () => {
        let threshold = findOption("LivenessThreshold")
        if (threshold) {
            threshold.value = "80"
        } else {
            threshold = {key: "LivenessThreshold", value: "80"}
            props.options.advancedParameters?.push(threshold)
        }

        let livenessQuality = findOption("PassiveLivenessQualityThreshold")
        if (livenessQuality) {
            livenessQuality.value = "80"
        } else {
            livenessQuality = {key: "PassiveLivenessQualityThreshold", value: "80"}
            props.options.advancedParameters?.push(livenessQuality)
        }

        let livenessSensitivity = findOption("PassiveLivenessSensitivityThreshold")
        if (livenessSensitivity) {
            livenessSensitivity.value = "30"
        } else {
            livenessSensitivity = {key: "PassiveLivenessSensitivityThreshold", value: "30"}
            props.options.advancedParameters?.push(livenessSensitivity)
        }

        let imageSize = findOption("StrictQualityImageSize")
        if (imageSize) {
            imageSize.value = "921600"
        } else {
            imageSize = {key: "StrictQualityImageSize", value: "921600"}
            props.options.advancedParameters?.push(imageSize)
        }
        props.setQualityImageSize([1280, 720])
        props.setOptions({...props.options, livenessMode: LivenessMode.PASSIVE})
    }


    const handleSettingChange = (parameterGroup: parameter) => {
        switch (parameterGroup) {
            case parameter.PC:
                handlePcSettings()
                break;
            case parameter.MOBILE:
                handleMobileSettings()
                break;
        }
    }

    const showCell = (text: string, bold?: boolean) => {
        return(
            <TableCell width="25%" align="left" className={bold? classes.cellBold : classes.cell}>
                {text}
            </TableCell>
        );
    }

    return(
        <>
            <TableContainer component={Paper} className={classesContainer.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TextCell head colspan={3} title="ISO 30103-7 parameters" subtitle="By default, the FaceVerification product does not use ISO 30103-7 parameter set."/>
                            <TableCell align="right">
                                <Button onClick={handleApply} className={props.active? classes.button : classes.disabledButton} color="primary" variant="contained" disabled={!props.active}>
                                    Apply
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.rowHead}>
                            {showCell("Parameter", true)}
                            {showCell("PC", true)}
                            {showCell("Mobile", true)}
                            {showCell("Remarks", true)}
                        </TableRow>
                        <TableRow className={classes.rowEven}>
                            {showCell("Liveness mode", true)}
                            {showCell("Passive")}
                            {showCell("Passive")}
                            {showCell("")}
                        </TableRow>
                        <TableRow className={classes.rowOdd}>
                            {showCell("Liveness threshold", true)}
                            {showCell("70 (default 50)")}
                            {showCell("80 (default 50)")}
                            {showCell("")}
                        </TableRow>
                        <TableRow className={classes.rowEven}>
                            {showCell("Passive Liveness Quality", true)}
                            {showCell("60 (default 40)")}
                            {showCell("80 (default 40)")}
                            {showCell("")}
                        </TableRow>
                        <TableRow className={classes.rowOdd}>
                            {showCell("Image resolution", true)}
                            {showCell("1280x720")}
                            {showCell("1280x720")}
                            {showCell("Only front camera can be used.")}
                        </TableRow>
                        <TableRow className={classes.rowEven}>
                            {showCell("Passive Liveness sensitivity", true)}
                            {showCell("default (30)")}
                            {showCell("default (30)")}
                            {showCell("")}
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <b>Note:</b> If PC uses external camera, the camera should be positioned is such way, that it is on top/bottom of the monitor and user could clearly se him/her self.<br/><br/>
                                Lower values are used that are still reliant for spoof prevention, yet make the capturing process easier for the user.<br/><br/>
                                PC values may also be used for FaceVerification Web service, yet it is strongly advised to stick to default configuration.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <ISOConfirmationDialog open={open} setOpen={setOpen} apply={handleSettingChange}/>
        </>
    );
}

export default ISOLivenessTable