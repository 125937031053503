import { Grid, Theme, useMediaQuery } from "@mui/material"
import { FVInitParams } from "neurotec-faceverification-management-client"
import { useSnackbar } from "notistack"
import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { NavigationContext } from "../../../components/Navigation/store/context"
import OperationButtonContained from "../../../components/Operations/styled/OperationButtonContained"
import OperationButtonOutlined from "../../../components/Operations/styled/OperationButtonOutlined"
import OperationTextGrid from "../../../components/Operations/styled/OperationTextGrid"
import OperationTypographyInstruction from "../../../components/Operations/styled/OperationTypographyInstruction"
import OperationTypographyText from "../../../components/Operations/styled/OperationTypographyText"
import { fvInitParamsStorage } from "../../../config/const"
import { getInitParamsByManagementConfig, getManagementInfo } from "../../../helpers/startOperation"
import { useLocalStorage, initialFvParameters } from "../../../helpers/useLocalStorage"
import { startSession } from "../../../helpers/webRTC"
import { AppContext } from "../../../store/context"
import { addSession, showMessage, showOperationSummary } from "../../../store/reducer"
import { IOperationsStatus, Operations } from "../../../types/FaceVerification"
import { ModalityType } from "../../Enroll/Enroll"
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream"
import { setOptionsActive } from "../../../components/Navigation/store/reducer"

interface ICheckSideBar {
    checkState: IOperationsStatus
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    getNextModality: () => void
    activeModality: ModalityType | null
    childRef: React.RefObject<RefVideoStream>
}

const CheckSideBar: React.FC<ICheckSideBar> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(AppContext).dispatch;
    const history = useHistory()
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (props.checkState === IOperationsStatus.SUCCESS) {
            props.getNextModality()
        }
        if (props.checkState === IOperationsStatus.ACTIVE) {
            navigationDispatch(setOptionsActive(false))
        } else {
            navigationDispatch(setOptionsActive(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.checkState, navigationDispatch])

    const restart = () => {
        dispatch(showOperationSummary(""));
        props.setCheckState(IOperationsStatus.IDLE)
    }

    const check = async () => {
        if (options) {
            const managementInfo = await getManagementInfo();
            if (!managementInfo) {
                enqueueSnackbar("Could not connect to the server", {variant: "error"})
                return;
            }
            let initParams = getInitParamsByManagementConfig(managementInfo, options);
            initParams.type = Operations.CHECKS;
            initParams.subjectId = "";
            startSession(Operations.CHECKS, initParams).then(res => {
                props.setCheckState(IOperationsStatus.ACTIVE)
                dispatch(addSession(res.data, initParams));
            }).catch(err => {
                props.setCheckState(IOperationsStatus.IDLE)
                console.log(err)
                dispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
            });
        } else {
            props.setCheckState(IOperationsStatus.IDLE)
            dispatch(showMessage({ message: "Options must be specified", type: "error" }));
        }
    }

    const getButtons = () => {
        if (props.activeModality === ModalityType.OVERVIEW) {
            return (
                <OperationButtonContained onClick={() => history.push("/")} color="primary" variant="contained">
                    Finish
                </OperationButtonContained>
            )
        }
        switch(props.checkState) {
            case IOperationsStatus.IDLE:
                return (
                    <OperationButtonContained onClick={check} color="primary" variant="contained">
                        Start capture
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.ACTIVE:
                return (
                    <OperationButtonContained onClick={props.childRef.current?.cancel} color="primary" variant="contained">
                        Cancel
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.DONE:
                return (
                    <>
                        <OperationButtonContained onClick={restart} color="primary" variant="contained">
                            Recapture
                        </OperationButtonContained>
                        <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                            Home
                        </OperationButtonOutlined>
                    </>
                )
            case IOperationsStatus.SUCCESS:
                return (
                    <>
                        <OperationButtonContained onClick={restart} color="primary" variant="contained">
                            Recapture
                        </OperationButtonContained>
                        <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                            Home
                        </OperationButtonOutlined>
                    </>
                ) 
        }
    }

    const getModalityText = () => {
        switch(props.activeModality) {
            case ModalityType.FACE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Take a selfie
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Adjust your position to place your face into oval frame and press <b>Start capture</b>.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.OVERVIEW:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Preview captured data
                                </OperationTypographyInstruction>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
        }
    }

    return (
        <OperationTextGrid item md={2} xs={12} glow={(props.checkState !== IOperationsStatus.ACTIVE)}>
            {getModalityText()}
        </OperationTextGrid>
    )
}

export default CheckSideBar