import { Button, IconButton, Paper, TableBody, TableContainer, TableHead, TableRow, TextField, Theme, styled, useMediaQuery } from "@mui/material"
import { SettingTable, settingUseStyles } from "../Settings";
import TextCell from "../components/TextCell";
import { TableCell } from "@material-ui/core";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { FVAdvancedInitParams } from "neurotec-faceverification-management-client";
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";

const ConfirmButton = styled(Button)(() => ({
    opacity: "100%",
    backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    width: "100%"
}));

interface IDeveloperProps {
    setNewOption: (key: string, value: string) => void
    removeOption: (key: string) => void
    options: FVAdvancedInitParams[]
}

const Developer: React.FC<IDeveloperProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const classes = settingUseStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [key, setKey] = useState("")
    const [value, setValue] = useState("")
    const [deleteKey, setDeleteKey] = useState("")
    const [openDialog, setOpenDialog] = useState(false)

    const handleAddButtonClick = () => {
        props.setNewOption(key, value)
        enqueueSnackbar("New advanced option set, key: " + key + " , value: " + value, {variant: "info"})
        setKey("")
        setValue("")
    }

    const handleRemoveButtonClick = (key: string | undefined) => {
        if (typeof key === "string") {
            setOpenDialog(true)
            setDeleteKey(key)
        }
    }

    const handleDelete = () => {
        setOpenDialog(false)
        props.removeOption(deleteKey)
    }

    const enterTableRow = () => {
        if (isMobile) {
            return (
                <>      
                <TableRow>
                    <TableCell style={{paddingBottom: "0px"}} colSpan={3}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="Key"
                            name="Key"
                            label="Key"
                            color="primary"
                            value={key}
                            onChange={(event) => setKey(event.target.value)}
                            autoFocus={isMobile}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingTop: "0px"}} colSpan={3}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="Value"
                            name="Value"
                            label="Value"
                            color="primary"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            autoFocus={isMobile}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={3}>
                        <ConfirmButton color="primary" variant="contained" onClick={handleAddButtonClick}>
                            Add
                        </ConfirmButton>
                    </TableCell>
                </TableRow>
                </>
            )
        } else {
            return (
                <>
                <TableRow>
                    <TableCell colSpan={2}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="Key"
                            name="Key"
                            label="Key"
                            color="primary"
                            value={key}
                            onChange={(event) => setKey(event.target.value)}
                            autoFocus={isMobile}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="Value"
                            name="Value"
                            label="Value"
                            color="primary"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            autoFocus={isMobile}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={3}>
                        <ConfirmButton color="primary" variant="contained" onClick={handleAddButtonClick}>
                            Add
                        </ConfirmButton>
                    </TableCell>
                </TableRow>
                </>
            )
        }
    }

    const getOptionList = () => {
        return props.options.map((option, index) => (
            <TableRow key={index}>
                <TableCell style={{overflowWrap: "break-word"}}>
                    {option.key}
                </TableCell>
                <TableCell>
                    {option.value}
                </TableCell>
                <TableCell align="right">
                    <IconButton color="error" onClick={() => handleRemoveButtonClick(option.key)}>
                        <CloseIcon />
                    </IconButton >
                </TableCell>
            </TableRow>
            )
        )
    }

    return (
        <>
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell head colspan={isMobile? 3 : 3} title="Developer options" subtitle="Secret developer options" subtitleSecond="WARNING: don't change anything if you don't know what you are doing"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {enterTableRow()}
                    {getOptionList()}
                </TableBody>
            </SettingTable>
        </TableContainer>
        <ConfirmationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            confirm={handleDelete}
            title={"Remove option"}
            text={"Remove this option: " + deleteKey}
            cancelText={"Cancel"}
            confirmText={"Delete"}
            confirmColor="error"
        />
        </>
    )
}

export default Developer