import { FVAdvancedInitParams, FVInitParams, SessionData } from "neurotec-faceverification-management-client";
import { IMessage, IModality, IOperationState } from "./actions";

export interface AppState {
    session: SessionData | null,
    options: FVInitParams | null,
    defaultOptions: FVAdvancedInitParams[],
    showOperationSummary: string,
    showQrCode: string,
    qrSession: boolean,
    message: IMessage,
    activeModalities: IModality,
    operationState: IOperationState
    checksTitle: string
    prefix: string | null
}

export const initAppState: AppState = {
    session: null,
    showOperationSummary: "",
    showQrCode: "",
    qrSession: false,
    message: {
        message: '',
        type: "info"
    },
    options: null,
    defaultOptions: [],
    activeModalities: {faceModality: false, voiceModality: false},
    operationState: {subjectId: "", modalityState: []},
    checksTitle: "Face Checks Liveness & ICAO",
    prefix: null
}