import { Grid, Theme, useMediaQuery } from "@mui/material"
import { useContext, useEffect } from "react"
import { NavigationContext } from "../../../components/Navigation/store/context"
import { AppContext } from "../../../store/context"
import { FVInitParams } from "neurotec-faceverification-management-client"
import { useSnackbar } from "notistack"
import { setOptionsActive } from "../../../components/Navigation/store/reducer"
import { getManagementInfo, getInitParamsByManagementConfig } from "../../../helpers/startOperation"
import { startSession } from "../../../helpers/webRTC"
import { showOperationSummary, addSession, showMessage } from "../../../store/reducer"
import React from "react"
import { useHistory } from "react-router-dom"
import { fvInitParamsStorage } from "../../../config/const"
import { useLocalStorage, initialFvParameters } from "../../../helpers/useLocalStorage"
import { RefVideoStream } from "../../VideoStream/BiometricFaceStream"
import { ModalityType } from "../Enroll"
import OperationButtonContained from "../../../components/Operations/styled/OperationButtonContained"
import OperationButtonOutlined from "../../../components/Operations/styled/OperationButtonOutlined"
import OperationTextGrid from "../../../components/Operations/styled/OperationTextGrid"
import OperationTypographyInstruction from "../../../components/Operations/styled/OperationTypographyInstruction"
import OperationTypographyText from "../../../components/Operations/styled/OperationTypographyText"
import { IOperationsStatus, Operations } from "../../../types/FaceVerification"

interface IEnrollSideBar {
    subjectId: string
    checkState: IOperationsStatus
    setCheckState: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    getNextModality: () => void
    activeModality: ModalityType | null
    childRef: React.RefObject<RefVideoStream>
}

const EnrollSideBar: React.FC<IEnrollSideBar> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const {state, dispatch} = useContext(AppContext);
    const history = useHistory()
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [options] = useLocalStorage<FVInitParams>(fvInitParamsStorage, initialFvParameters);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (props.checkState === IOperationsStatus.SUCCESS) {
            props.getNextModality()
        }
        if (props.checkState === IOperationsStatus.ACTIVE) {
            navigationDispatch(setOptionsActive(false))
        } else {
            navigationDispatch(setOptionsActive(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.checkState, navigationDispatch])

    const restart = () => {
        dispatch(showOperationSummary(""));
        props.setCheckState(IOperationsStatus.IDLE)
    }

    const enroll = async () => {
        const managementInfo = await getManagementInfo();
        if (!managementInfo) {
            enqueueSnackbar("Could not connect to the server", {variant: "error"})
            return;
        }
        let initParams: FVInitParams = getInitParamsByManagementConfig(managementInfo, options);
        initParams.subjectId = props.subjectId;
        initParams.type = Operations.ENROLL;
        startSession(Operations.ENROLL, initParams).then(res => {
            props.setCheckState(IOperationsStatus.ACTIVE)
            dispatch(addSession(res.data, initParams));
        }).catch(err => {
            props.setCheckState(IOperationsStatus.IDLE)
            console.log(err)
            dispatch(showMessage({ message: "Unable to create session: " + err.message, type: "error" }))
        })
    }

    const handleSubjectId = () => {
        if (state.prefix) {
            if (props.subjectId.startsWith(state.prefix)) {
                return props.subjectId.substring(state.prefix.length + 1)
            }
        }
        return props.subjectId;
    }

    const getButtons = () => {
        if (props.activeModality === ModalityType.OVERVIEW) {
            return (
                <OperationButtonContained onClick={() => history.push("/")} color="primary" variant="contained">
                    Finish
                </OperationButtonContained>
            )
        }
        switch(props.checkState) {
            case IOperationsStatus.IDLE:
                return (
                    <OperationButtonContained onClick={enroll} color="primary" variant="contained">
                        Start enroll
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.ACTIVE:
                return (
                    <OperationButtonContained onClick={props.childRef.current?.cancel} color="primary" variant="contained">
                        Cancel
                    </OperationButtonContained>
                ) 
            case IOperationsStatus.DONE:
                return (
                    <>
                        <OperationButtonContained onClick={restart} color="primary" variant="contained">
                            Restart enroll
                        </OperationButtonContained>
                        <OperationButtonOutlined color="primary" variant="outlined" onClick={() => {history.push("/")}}>
                            Home
                        </OperationButtonOutlined>
                    </>
                )
        }
    }

    const getModalityText = () => {
        switch(props.activeModality) {
            case ModalityType.FACE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Take a selfie
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Adjust your position to place your face into oval frame and press <b>Start enroll</b>.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.VOICE_MODALITY:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Record your voice
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    Press <b>Start enroll</b> and speak.
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
            case ModalityType.OVERVIEW:
                return (
                    <Grid item xs={12}>
                        {!isMobile || (props.checkState !== IOperationsStatus.ACTIVE && props.checkState !== IOperationsStatus.SUCCESS)? 
                            <>
                                <OperationTypographyInstruction>
                                    Preview captured data
                                </OperationTypographyInstruction>
                                <OperationTypographyText>
                                    <b>SubjectId:</b> {handleSubjectId()}
                                </OperationTypographyText>
                            </>
                            : null
                        }
                        {getButtons()}
                    </Grid>
                )
        }
    }

    return (
        <OperationTextGrid item md={2} xs={12} glow={(props.checkState !== IOperationsStatus.ACTIVE && isMobile)}>
            {getModalityText()}
        </OperationTextGrid>
    )
}

export default EnrollSideBar