import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import TextCell from "../components/TextCell";
import { useState } from "react";
import SwitchCell from "../components/SwitchCell";
import { ISliderInterface } from "../components/SliderCell";
import { FVInitParams } from "neurotec-faceverification-management-client";
import { IICAOPositionalWarnings } from "../../../types/FaceVerification";
import { ISelectValue } from "../components/SelectCell";
import SelectCheckmarksCell from "../components/SelectCheckmarksCell";
import { DefaultButton, SettingTable, TableCellButton, settingUseStyles } from "../Settings";
import SliderRow from "../components/SliderRow";
import { useSnackbar } from "notistack";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";


interface IICAO {
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setNewOption: (op: ISliderInterface) => void,
    resetValueToDefault: (name: string) => void,
    options: FVInitParams,
    setOptions: React.Dispatch<React.SetStateAction<FVInitParams>>,
}

const ICAO: React.FC<IICAO> = (props) => {

    const classes = settingUseStyles()
    const [active, setActive] = useState<boolean>((props.options?.checkIcaoCompliance)? true : false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const setBackgroundRemoval = (bool: boolean) => {
        let backgroundRemoval = props.options.advancedParameters?.find((e) => e.key === "IcaoRemoveBackground")
        if (backgroundRemoval) {
            backgroundRemoval.value = bool.toString();
            props.setOptions({...props.options, advancedParameters: props.options.advancedParameters})
        } else {
            if (props.options.advancedParameters)
                props.setOptions({...props.options, advancedParameters: [...props.options.advancedParameters, {key: "IcaoRemoveBackground", value: bool.toString()}]})
        }
    }

    const getBackgroundRemoval = () => {
        let backgroundRemoval = props.options.advancedParameters?.find((e) => e.key === "IcaoRemoveBackground")
        if (backgroundRemoval) {
            if (backgroundRemoval.value) {
                if (backgroundRemoval.value === "true")
                    return true
                else 
                    return false
            }
        }
        return false;
    }

    const setFilter = (str: string) => {
        let filter = props.options.advancedParameters?.find((e) => e.key === "IcaoWarningsFilter")
        if (filter) {
            filter.value = str
            props.setOptions({...props.options, advancedParameters: props.options.advancedParameters})
        } else {
            if (props.options.advancedParameters)
                props.setOptions({...props.options, advancedParameters: [...props.options.advancedParameters, {key: "IcaoWarningsFilter", value: str}]})
        }
    }

    const getEnumValuesAndKey = (): ISelectValue[] => {
        return Object.keys(IICAOPositionalWarnings).map((e) => {
            return {key: e, value: IICAOPositionalWarnings[e as keyof typeof IICAOPositionalWarnings]}
        }).filter((el) => el !== undefined) as unknown as ISelectValue[]
    }

    const setIcao = (icao: boolean) => {
        setActive(icao)
        props.setOptions({...props.options, checkIcaoCompliance: icao})
    }

    const handleResetClick = () => {
        enqueueSnackbar("ICAO settings reset", {variant: "info"})
        props.resetValueToDefault("IcaoWarningsFilter")
        props.resetValueToDefault("IcaoBackgroundColor")
        props.resetValueToDefault("IcaoRemoveBackground")
        props.resetValueToDefault("IcaoSharpnessThreshold")
        props.resetValueToDefault("IcaoBackgroundUniformityThreshold")
        props.resetValueToDefault("IcaoGrayscaleDensityThreshold")
        props.resetValueToDefault("IcaoSaturationThreshold")
        props.resetValueToDefault("IcaoExpressionThreshold")
        props.resetValueToDefault("IcaoDarkGlassesThreshold")
        props.resetValueToDefault("IcaoBlinkThreshold")
        props.resetValueToDefault("IcaoMouthOpenThreshold")
        props.resetValueToDefault("IcaoLookingAwayThreshold")
        props.resetValueToDefault("IcaoRedEyeThreshold")
        props.resetValueToDefault("IcaoFaceDarknessThreshold")
        props.resetValueToDefault("IcaoUnnaturalSkinToneThreshold")
        props.resetValueToDefault("IcaoWashedOutThreshold")
        props.resetValueToDefault("IcaoPixelationThreshold")
        props.resetValueToDefault("IcaoSkinReflectionThreshold")
        props.resetValueToDefault("IcaoGlassesReflectionThreshold")
        props.resetValueToDefault("IcaoHeavyFrameThreshold")
        props.resetValueToDefault("IcaoOcclusionThreshold")
        props.resetValueToDefault("IcaoResolutionThreshold")
        props.resetValueToDefault("IcaoMotionBlurThreshold")
        props.resetValueToDefault("IcaoCompressionArtifactsThreshold")
        props.resetValueToDefault("IcaoOverexposureThreshold")
        props.resetValueToDefault("IcaoUnderexposureThreshold")
        props.resetValueToDefault("IcaoGlassesThreshold")
        props.resetValueToDefault("IcaoHatThreshold")
    }

    return(
        <>
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell width="25%" head title="ICAO" subtitle=""/>
                        <TableCellButton align="right" colSpan={isMobile? 1 : 2}>
                            <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                Reset to default
                            </DefaultButton>
                        </TableCellButton>
                        <SwitchCell state={active} setState={setIcao}/>
                    </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TextCell title="Remove background" subtitle="Enables or disables ICAO token image background removal"/>
                    <SwitchCell disabled={!active} colSpan={isMobile? 2 : 3} state={getBackgroundRemoval()} setState={setBackgroundRemoval}/>
                </TableRow>
                <SliderRow
                    mobile={isMobile}
                    textTitle="Background color"
                    subtitle="Background color of removed background if ICAO token image background removal is enabled."
                    title="Color"
                    option={props.findFVSliderInterface("IcaoBackgroundColor")}
                    defaultOption={props.findDefaultFVSliderInterface("IcaoBackgroundColor")}
                    setOption={props.setNewOption}
                    minValue={0}
                    maxValue={255}
                    type="raisingMono"
                    active={getBackgroundRemoval() && active}
                    lockMaxValue
                />
                <TableRow>
                    <TextCell title="Warnings filter" subtitle="A list of positional warnings to ignore."/>
                    <SelectCheckmarksCell 
                        title="Filter"
                        active={active}
                        colSpan={isMobile? 2 : 3}
                        menuItems={getEnumValuesAndKey()}
                        value={props.options.advancedParameters?.find((e) => e.key === "IcaoWarningsFilter")?.value}
                        setFilter={setFilter}
                    />
                </TableRow>
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Sharpness"
                        subtitle="Determines the amount of detail an imaging system can reproduce."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoSharpnessThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoSharpnessThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating better sharpness."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Background uniformity"
                        subtitle="Determines the consistency and homogeneity of the background in an image or video frame."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoBackgroundUniformityThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoBackgroundUniformityThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100]. A score of 0 indicates a highly varied or cluttered background with multiple colors, patterns, or objects, whereas a score of 100 signifies a completely uniform background with a single color or shade."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Grayscale density"
                        subtitle="Determines the density of black and white pixels."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoGrayscaleDensityThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoGrayscaleDensityThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher grayscale density."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Saturation"
                        subtitle="Determines the intensity or purity of a color."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoSaturationThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoSaturationThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100], with 0 representing a completely desaturated or grayscale image, and 100 indicating full, vibrant color saturation. A higher score denotes better or more vivid saturation, making the colors appear more vibrant and pure."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Expression"
                        subtitle="Determines if the face exhibits a neutral expression."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoExpressionThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoExpressionThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating that person is smiling."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Dark glasses"
                        subtitle="Determines whether glasses are transparent or dark."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoDarkGlassesThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoDarkGlassesThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating probability that dark glasses are present."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Blink"
                        subtitle="Determines whether eyes are closed or opened."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoBlinkThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoBlinkThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating that person is blinking."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Mouth open"
                        subtitle="Determines whether mouth is opened or not."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoMouthOpenThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoMouthOpenThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating probability that mouth is opened. In theory this value should be a percentage."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Looking away"
                        subtitle="Determines the degree to which an individual's gaze deviates from directly facing the camera."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoLookingAwayThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoLookingAwayThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 suggests that the person is looking straight at the camera, whereas a score of 100 indicates a complete diversion from the camera's direction. Higher values imply that the person is more likely looking away."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Red eye"
                        subtitle="Determines the presence and intensity of redness in an individual's eyes."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoRedEyeThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoRedEyeThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 indicates no detectable redness in the eyes, while a score of 100 signifies pronounced red eyes. Higher values are indicative of greater redness"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Face darkness"
                        subtitle="Determines the underexposure level of an individual's face within an image."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoFaceDarknessThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoFaceDarknessThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 suggests that the face is well-lit and appropriately exposed, while a score of 100 indicates severe underexposure, resulting in a darkened facial appearance"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Unnatural skin tone"
                        subtitle="Determines the naturalness of an individual's skin tone in an image."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoUnnaturalSkinToneThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoUnnaturalSkinToneThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score closer to 0 suggests that the skin tone appears altered or unnatural, while a score closer to 100 indicates that the skin tone appears authentic and true to life. Higher values are indicative of a more natural-looking skin tone."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Washed out"
                        subtitle="Determines the sharpness and vibrancy of colors in an image."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoWashedOutThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoWashedOutThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info='Produces value on [0, 100]. A score of 0 indicates that the colors are vibrant and well-defined, while a score of 100 signifies that the colors appear faded or muted, lacking in depth and richness. Higher scores indicate that the colors in the image are more "washed out" or desaturated'
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Pixelation"
                        subtitle="Determines the extent of pixelation or the visibility of individual pixels in an image."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoPixelationThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoPixelationThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 suggests a high-resolution, smooth image without noticeable pixel boundaries, whereas a score of 100 denotes severe pixelation where individual pixels are clearly discernible, often leading to a blocky or grainy appearance. Higher values represent increased levels of pixelation."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Skin reflection"
                        subtitle="Determines the presence and intensity of light reflections on an individual's face within an image."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoSkinReflectionThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoSkinReflectionThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 suggests the absence of noticeable reflections, whereas a score of 100 indicates significant light reflections on the face. Higher values signify an increasing presence of reflections."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Glasses reflection"
                        subtitle="Determines whether glasses are present or not."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoGlassesReflectionThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoGlassesReflectionThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100]. A score of 0 indicates no discernible reflection on the glasses, while a score of 100 signifies pronounced light reflection on the glasses. Higher values represent an increased presence of reflections"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Heavy frame"
                        subtitle="Determines if glasses have a thick frame or not. Thick frame is concidered for anything thicker than sunglasses frame."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoHeavyFrameThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoHeavyFrameThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating that glasses frame is heavy"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Occlusion"
                        subtitle="Determines the area of the face that is occluded. The face region extends from top of forehead to chin, and from ear to ear. Ignores transparent eye-glasses and frames."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoOcclusionThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoOcclusionThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating a percentage of face that is occluded by some object"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Resolution"
                        subtitle="Determines how accurately an image captures the fine details of the human face, indicating any deviation from perfection."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoResolutionThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoResolutionThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active={active}
                        lockMaxValue
                        info="Highest values are set to an uncompressed image with IED of 256 pixels or higher that is perfectly focused and in all respects pristine. Produces a value on [0, 100] with higher values indicating a face is more detailed and sharp."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Motion blur"
                        subtitle="Determines the extent to which motion blur affects the face in an image. Output should not report motion blur for an image affected by solely de-focus, or high compression."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoMotionBlurThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoMotionBlurThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating more motion blur is present in the face area."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Compression artifacts"
                        subtitle="Determines the presence of lossy compression artifacts (for JPEG these exist on an 8x8 grid)."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoCompressionArtifactsThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoCompressionArtifactsThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating that more compression artifacts are present in the face area."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Overexposure"
                        subtitle="Determines the score representing proportion of face that is overexposed."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoOverexposureThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoOverexposureThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Higher values indicating that bigger proportion of face is overexposed"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Underexposure"
                        subtitle="Determines the score representing proportion of face that is underexposed."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoUnderexposureThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoUnderexposureThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating that bigger proportion of face is underexposed."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Glasses"
                        subtitle="Determines whether glasses are present or not."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoGlassesThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoGlassesThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces a value on [0, 100] with higher values indicating probability that person is wearing some sort of glasses."
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Hat"
                        subtitle="Determines whether hat is present or not."
                        title="Threshold range"
                        option={props.findFVSliderInterface("IcaoHatThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("IcaoHatThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="falling"
                        active={active}
                        lockMaxValue
                        info="Produces value on [0, 100] with higher values indicating that person is wearing some kind of a head covering."
                    />
                </TableBody>
            </SettingTable>
        </TableContainer>
        <ConfirmationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            title="Reset ICAO settings"
            text="Reset ICAO settings to default values?"
            cancelText="Cancel"
            confirmText="Yes"
            confirm={handleResetClick}
            confirmColor="error"
        />
        </>
    );
}

export default ICAO;