import {IconButton, styled} from '@mui/material'
import { Operation } from 'neurotec-faceverification-management-client'
import {Image as NImage} from 'neurotec-faceverification-management-client'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { THEME } from '../../config'

const SummaryDiv = styled('div')(() => ({
    position: "absolute",
    zIndex: 3,
    top: 0,
    left: 0,
    transform: "scaleX(-1)",
    borderRadius: "5px",
    alignItems: "center",
    display: "flex",
}));

const Canvas = styled('canvas')(() => ({
    width: "100%",
    height: "100%",
    borderRadius: "5px",
}));

const SelectionIconButton = styled(IconButton)(() => ({
    zIndex: 4,
    position: "absolute",
    backgroundColor: THEME.palette.primary.main
}));

interface IOperationSummaryProps {
    canvasRef?: React.RefObject<HTMLCanvasElement>,
    divRef?: React.RefObject<HTMLDivElement>,
    showStream: (bool: boolean) => void,
    tokenImage?: boolean,
    operationInfo: Operation | undefined,
    imageInfo: NImage | undefined
}

const OperationSummary: React.FC<IOperationSummaryProps> = (props) => {

    const [showTokenImage, setShowTokenImage] = useState<boolean>(true)
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    const getSubjectImage = useCallback(() => {
        if (props.imageInfo) {
            if (props.tokenImage) {
                if (showTokenImage) {
                    return props.imageInfo.tokenImage as unknown as string;
                } else {
                    return props.imageInfo.image as unknown as string;
                }
            } else {
                if (props.imageInfo.image) {
                    return props.imageInfo.image as unknown as string;
                }
            }
        }
        return undefined;
    }, [props.imageInfo, props.tokenImage, showTokenImage])

    const getImageRect = useCallback(() => {
        if (props.operationInfo && props.operationInfo.boundingRect) {
            return props.operationInfo.boundingRect;
        }
        return {};
    }, [props.operationInfo])

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current && props.imageInfo) {
            const canvas = canvasRef.current;
            const div = divRef.current;
            if (props.canvasRef && props.canvasRef.current) {
                div.style.width = props.canvasRef.current.width + "px"
                div.style.height = props.canvasRef.current.height + "px"
                div.style.top = props.canvasRef.current.offsetTop + "px"
                div.style.left = props.canvasRef.current.offsetLeft + "px"  
            } else if (props.divRef && props.divRef.current) {
                div.style.width = props.divRef.current.clientWidth + "px"
                div.style.height = props.divRef.current.clientHeight + "px"
                div.style.top = props.divRef.current.offsetTop + "px"
                div.style.left = props.divRef.current.offsetLeft + "px"  
            }
            canvas.width = div.clientWidth;
            canvas.height = div.clientHeight;
            canvas.style.top = div.offsetTop + "px";
            canvas.style.left = div.offsetLeft + "px";      
            const ctx = canvas.getContext("2d"); 

            if (ctx && getSubjectImage()) {
                const image = new Image();
                image.src = "data:image/png;base64, " + getSubjectImage();
                image.onload = () => {
                    // Setting image width/heigth scale
                    const aspectRatio = image.width / image.height;

                    let canvasCurrentWidth = canvas.width;
                    let canvasCurrentHeight = canvas.height;

                    if (aspectRatio > 1) { //image is in landscape
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                    }
                    else { // image is in portrait
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio
                    }

                    const horizontalCenter = (canvas.width - canvasCurrentWidth) / 2;
                    const verticalCenter = (canvas.height - canvasCurrentHeight) / 2;

                    ctx.clearRect(0, 0, canvas.width, canvas.height)

                    if (props.tokenImage && showTokenImage) {
                        ctx.fillStyle = "#f3f3f3"
                        ctx.fillRect(0, 0, canvas.width, canvas.height)
                    }

                    ctx.drawImage(image, horizontalCenter, verticalCenter, canvasCurrentWidth, canvasCurrentHeight);
                    if (!props.tokenImage || !showTokenImage) {
                        ctx.strokeStyle = THEME.palette.success.dark
                        ctx.beginPath();
                        ctx.lineWidth = 5
                        const rect = getImageRect();
                        const widthProportion = image.width / canvasCurrentWidth;
                        const heightProportion = image.height / canvasCurrentHeight;
                        if (rect.x && rect.y && rect.width && rect.height)
                            ctx.rect(
                                (rect.x / widthProportion) + horizontalCenter,
                                (rect.y / heightProportion) + verticalCenter,
                                rect.width / widthProportion,
                                rect.height / heightProportion);
                        ctx.stroke()
                    }
                }
            }
        }
    }, [getImageRect, getSubjectImage, props.tokenImage, props.canvasRef, showTokenImage, props.imageInfo, props.divRef]);

    useEffect(() => {
        const clearAndDraw = () => {
            if (canvasRef.current) {
                const canvas = canvasRef.current;
                const ctx = canvas.getContext("2d");
                ctx?.clearRect(0, 0, canvas.width, canvas.height);
                draw();
            }
        }
        clearAndDraw();
    }, [props.imageInfo, props.operationInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        draw()
    }, [showTokenImage]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleImageSelection = () => {
        if (props.tokenImage) {
            setShowTokenImage(!showTokenImage)
        }
    }

    const getImageArrowSelection = (left: boolean) => {
        return (
            props.tokenImage?
                <SelectionIconButton
                    onClick={handleImageSelection}
                    style={{right: left? "15px" : undefined, left: left? undefined : "15px"}}
                >
                    <ArrowBackIosNewIcon style={{color: THEME.palette.text.secondary, transform: left? "scale(-1)" : "scale(1)"}} />
                </SelectionIconButton >
            : null
        )
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <SummaryDiv ref={divRef}>
            {getImageArrowSelection(true)}
            {getImageArrowSelection(false)}
            <Canvas ref={canvasRef} />
        </SummaryDiv>
    )
}

export default OperationSummary
