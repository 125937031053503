import React, { ForwardedRef, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { AppContext } from '../../store/context';
import { showMessage, showOperationSummary, setOptions, removeQrSession, setOperationState, setActiveModalities } from '../../store/reducer';
import jsQR from "jsqr";
import Message from '../../components/Message/Message';
import { IOperationsStatus } from '../../types/FaceVerification';
import ConfirmationDialog from '../../components/Misc/Dialog/ConfirmationDialog';
import OperationOnPhoneDialog from '../../components/Misc/Dialog/OperationOnPhoneDialog';
import { Buffer } from 'buffer';
import BiometricDiv from './BiometricDiv';
import { SubjectsAPI } from '../../config/management-api';
import { TemplateModalitiesModalities } from 'neurotec-faceverification-management-client';
import { enqueueSnackbar } from 'notistack';
import { IModality, IOperationState, ModalityStateStatus } from '../../store/actions';
import { ModalityType } from '../Enroll/Enroll';

const constraints = {
    audio: false,
    video: {
        frameRate: { max: 30 },
        width: { ideal: 1280 },
        height: { ideal: 720 }
    }
};

export interface RefQRVideoStream {
    cancel?: () => void
    ref?: any
    setOperationStatus?: React.Dispatch<React.SetStateAction<IOperationsStatus>>
}

const QrStream: React.FC<RefQRVideoStream> = React.forwardRef((props: RefQRVideoStream, ref: ForwardedRef<RefQRVideoStream>) => {

    const divRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [frontMode, setFrontMode] = useState<boolean>(true)
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { state, dispatch } = useContext(AppContext)
    const qrId = useRef<number>(0);
    const setOperationStatus = props.setOperationStatus
    const [openCameraAccess, setOpenCameraAccess] = useState(false);
    const [openOperationOnCamera, setOpenOperationOnCamera] = useState(false);

    useImperativeHandle(ref, () => ({
        cancel: () => {
            handleCancel()
        }
    }));

    const handleCameraAccess = () => {
        setOpenCameraAccess(false)
        setOpenOperationOnCamera(true)
    }

    const setupCanvas = useCallback(() => {
        if (divRef.current && canvasRef.current) {
            let canvas = canvasRef.current;
            let div = divRef.current;
            canvas.width = div.clientWidth;
            canvas.height = div.clientHeight;
            canvas.style.top = div.offsetTop + "px";
            canvas.style.left = div.offsetLeft + "px";
        }
    }, []);

    const setupVideo = useCallback(async (frontFacing: boolean) => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            if (navigator.mediaDevices) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({...constraints, video: {facingMode: frontFacing ? "user" : "environment", ...constraints.video}})
                    video.addEventListener("loadeddata", setupCanvas);
                    video.srcObject = stream;
                    if (frontFacing || (stream.getTracks()[0].getCapabilities()?.facingMode?.[0] === "environment" && !frontFacing))
                        setFrontMode(frontFacing)
                } catch (error) {
                    setOpenCameraAccess(true)
                    if ((error as DOMException).name === "NotAllowedError") {
                        dispatch(showMessage({ message: "Permission denied", type: "error" }));
                    } else {
                        dispatch(showMessage({ message: (error as DOMException).message, type: "error" }));
                    }
                    console.error(error)
                }
            } else {
                setOpenCameraAccess(true)
                dispatch(showMessage({ message: "WebRTC not available", type: "error" }));
            }
        }
    }, [dispatch, setupCanvas]);

    const closeQrSession = useCallback((status: IOperationsStatus) => {
        if (setOperationStatus)
            setOperationStatus(status)
        console.debug("Closing QR sessions start");
        cancelAnimationFrame(qrId.current)
        dispatch(removeQrSession());
        console.debug("Closing QR sessions");
        dispatch(showMessage({ message: "", type: "info" }));
    }, [dispatch, setOperationStatus])

    const handleCancel = async () => {
        if (state.qrSession) {
            closeQrSession(IOperationsStatus.DONE)
        }
    }

    const setupVerification = useCallback((templateModalities: TemplateModalitiesModalities[], template: string) => {
        let operationStatus: IOperationState = {subjectId: "", modalityState: []}
        let activeModalities: IModality = {faceModality: false, voiceModality: false}

        operationStatus.modalityState.push({modality: ModalityType.QR_SCAN, state: ModalityStateStatus.DONE})
        templateModalities.forEach((m, i) => {
            if (m === TemplateModalitiesModalities.FaceModality) {
                operationStatus.modalityState.push({modality: ModalityType.FACE_MODALITY, state: i === 0 ? ModalityStateStatus.ACTIVE : ModalityStateStatus.TO_BE_DONE})
                activeModalities.faceModality = true
            } else if (m === TemplateModalitiesModalities.VoiceModality) {
                operationStatus.modalityState.push({modality: ModalityType.VOICE_MODALITY, state: i === 0 ? ModalityStateStatus.ACTIVE : ModalityStateStatus.TO_BE_DONE})
                activeModalities.voiceModality = true
            }
        })
        operationStatus.modalityState.push({modality: ModalityType.OVERVIEW, state: ModalityStateStatus.TO_BE_DONE})
        closeQrSession(IOperationsStatus.SUCCESS)
        dispatch(setOperationState(operationStatus))
        dispatch(setActiveModalities(activeModalities))
        dispatch(setOptions({verificationTemplate: template}))
    }, [dispatch, closeQrSession])

    const runQrReaderStream = useCallback(async (canvas: HTMLCanvasElement, video: HTMLVideoElement, locked: boolean) => {
        let ctx = canvas.getContext("2d", { willReadFrequently: true });
        if (ctx) {
            canvas.hidden = true;
            ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
            let dat = ctx.getImageData(0, 0, canvas.width, canvas.height);
            dispatch(showMessage({message: "Looking for QR code", type: "info"}))
            if (dat) {
                const code = jsQR(dat.data, dat?.width, dat?.height);  
                if (code && code.binaryData.length > 0 && checkIfTemplateValid(code.binaryData) && !locked) {
                    locked = true;
                    let u8 = new Uint8Array(code.binaryData);
                    let b64 = Buffer.from(u8).toString('base64');
                    SubjectsAPI.getTemplateModalities({getTemplateModalitiesRequest: {template: b64}}).then(res => {
                        if (res.data.modalities === undefined || res.data.modalities.length === 0) {
                            enqueueSnackbar("Could't get template modalities", {variant: "error"})
                            closeQrSession(IOperationsStatus.DONE)
                            return;
                        } else {
                            setupVerification(res.data.modalities, b64)
                            return;
                        }
                    }).catch(e => {
                        enqueueSnackbar("Could't get template modalities", {variant: "error"})
                        console.log(e)
                    })
                }
            }       
        }

        if (state.qrSession)
            qrId.current = requestAnimationFrame(() => runQrReaderStream(canvas, video, locked))
    }, [state.qrSession, qrId, closeQrSession, dispatch, setupVerification])

    const checkIfTemplateValid = (template: number[]): boolean => {
        if (template[0] === "F".charCodeAt(0) && template[1] === "V".charCodeAt(0))
            return true;
        return false
    }

    const setupQrReaderStream = useCallback(async () => {
        if (state.qrSession && videoRef.current && canvasRef.current) {      
            let video = videoRef.current;
            let canvas = canvasRef.current;
            let stream: MediaStream = video.srcObject as MediaStream;
            if (stream) {
                try {
                    qrId.current = requestAnimationFrame(() => runQrReaderStream(canvas, video, false));
                } catch (err: any) {
                    closeQrSession(IOperationsStatus.DONE)
                    let errorMessage: string = "Unable to start session";
                    console.log(errorMessage);
                    dispatch(showMessage({ message: errorMessage, type: "error" }));
                }
            } else {
                closeQrSession(IOperationsStatus.DONE)
                dispatch(showMessage({ message: "No stream available", type: "error" }));
            }
        }
    }, [runQrReaderStream, dispatch, closeQrSession, state.qrSession, qrId])

    useEffect(() => {
        let ref: HTMLVideoElement | undefined = undefined
        if (videoRef.current)
            ref = videoRef.current

        return () => {
            if (!(ref?.srcObject as MediaStream) || ref === undefined)
                return
            (ref?.srcObject as MediaStream).getTracks().forEach((track) => {
                track.enabled=false
                track.stop()
            })
            ref.srcObject = null
        }
    }, []);

    useLayoutEffect(() => {
        if (videoRef.current && !videoRef.current.srcObject)
            setupVideo(false);
        if (state.qrSession) {
            if (setOperationStatus)
                setOperationStatus(IOperationsStatus.ACTIVE)
            dispatch(showOperationSummary(""))
            setupQrReaderStream()
        }

        setupCanvas();
        window.addEventListener("resize", setupCanvas);
        return () => window.removeEventListener("resize", setupCanvas);
    }, [setupCanvas, setupVideo, setupQrReaderStream, state.qrSession, dispatch, setOperationStatus])
    
    return (
        <BiometricDiv ref={divRef}>
            <canvas ref={canvasRef} style={{ position: "absolute", zIndex: 2, top: 0, left: 0, transform: "scaleX(-1)" }} />
            <video ref={videoRef} autoPlay muted playsInline style={{ borderRadius: "5px", width: "auto", height: "100%", transform: frontMode ? "scaleX(-1)" : "scaleX(1)"}} />
            <Message />
            <ConfirmationDialog
                open={openCameraAccess}
                setOpen={setOpenCameraAccess}
                confirmColor="primary"
                title="Camera access"
                text="Camera access is necessary to perform this operation. Allow camera access or continue on mobile"
                cancelText="Close"
                confirmText="Continue on mobile"
                confirm={handleCameraAccess}
            />
            <OperationOnPhoneDialog
                open={openOperationOnCamera}
                setOpen={setOpenOperationOnCamera}
            />
        </BiometricDiv>
    )
})

export default QrStream