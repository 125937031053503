import { Box, styled } from "@mui/material";
import TitleBar from "./TitleBar";
import OperationSummary from "../../OperationSummary/OperationSummary";
import { useRef } from "react";
import { Operation } from "neurotec-faceverification-management-client";
import {Image as NImage} from 'neurotec-faceverification-management-client'


const StyledBox = styled(Box)(({theme}) => ({
    marginRight: "0.5rem",
    [theme.breakpoints.down("md")]: {
        marginRight: "0rem"
    }
}));

interface IFaceOverviewProps {
    tokenImage: boolean,
    operationInfo: Operation | undefined,
    imageInfo: NImage | undefined,
    bigger: boolean
}

const FaceOverview: React.FC<IFaceOverviewProps> = (props) => {

    const boxRef = useRef<HTMLDivElement>(null);

    const getEstimatedAgeText = () => {
        if (props.operationInfo?.age !== null && props.operationInfo?.age !== undefined && props.operationInfo?.age !== 254) {
            return "Estimated age: " + props.operationInfo?.age?.toString();
        } else {
            return ""
        }
    }

    return (
        <StyledBox>
            <TitleBar beginText="Face" endText={getEstimatedAgeText()}/>
            <Box ref={boxRef} style={{marginTop: "1rem", marginBottom: "1rem", height: props.bigger? "60vh" : "30vh"}}>
                <OperationSummary
                    divRef={boxRef}
                    showStream={() => {}}
                    tokenImage={props.tokenImage}
                    operationInfo={props.operationInfo}
                    imageInfo={props.imageInfo}
                />
            </Box>
        </StyledBox>
    )
}

export default FaceOverview;