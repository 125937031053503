import { IOperationsStatus, Status } from "../../types/FaceVerification";
import React, { ForwardedRef, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { removeSession, showMessage } from "../../store/reducer";
import { AppContext } from "../../store/context";
import { connectToJanus, setupDataChannel, setupWebRTC, stopSession } from "../../helpers/webRTC";
import { FVInitParams, JanusResponse } from "neurotec-faceverification-management-client";
import ConfirmationDialog from "../../components/Misc/Dialog/ConfirmationDialog";
import OperationOnPhoneDialog from "../../components/Misc/Dialog/OperationOnPhoneDialog";
import BiometricDiv from "./BiometricDiv";
import { ModalityType } from "../Enroll/Enroll";

const constraints = {
    audio: false,
    video: {
        facingMode: "user",
        frameRate: { max: 30 },
        width: { ideal: 1280 },
        height: { ideal: 720 }
    }
};

export interface RefVideoStream {
    cancel?: () => void
    ref?: any
    setOperationStatus?: React.Dispatch<React.SetStateAction<IOperationsStatus>>
    setOperationUUID?: (uuid: string) => void,
}

const BiometricFaceStream: React.FC<RefVideoStream> = React.forwardRef((props: RefVideoStream, ref: ForwardedRef<RefVideoStream>) => {

    const divRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rtcConnection = useRef<RTCPeerConnection | null>(null);
    const { state, dispatch } = useContext(AppContext)
    useImperativeHandle(ref, () => ({
        cancel: () => {
            closeSession(Status.CANCELED)
        }
    }));
    const setOperationStatus = props.setOperationStatus
    const [openCameraAccess, setOpenCameraAccess] = useState(false);
    const [openOperationOnCamera, setOpenOperationOnCamera] = useState(false);

    const setOperationUUID = props.setOperationUUID

    const handleCameraAccess = () => {
        setOpenCameraAccess(false)
        setOpenOperationOnCamera(true)
    }

    const setupCanvas = useCallback(() => {
        if (divRef.current && canvasRef.current) {
            let canvas = canvasRef.current;
            let div = divRef.current;
            canvas.width = div.clientWidth;
            canvas.height = div.clientHeight;
            canvas.style.top = div.offsetTop + "px";
            canvas.style.left = div.offsetLeft + "px";
        }
    }, []);

    const setupVideo = useCallback(() => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    video.addEventListener("loadeddata", setupCanvas);
                    video.srcObject = stream;
                }).catch((error) => {
                    setOpenCameraAccess(true)
                    if ((error as DOMException).name === "NotAllowedError") {
                        dispatch(showMessage({ message: "Permission denied", type: "error" }));
                    } else {
                        dispatch(showMessage({ message: (error as DOMException).message, type: "error" }));
                    }
                    console.error(error)
                })
            }
            else {
                setOpenCameraAccess(true)
                dispatch(showMessage({ message: "WebRTC not available", type: "error" }));
            }
        }
    }, [dispatch, setupCanvas]);

    const closeSession = useCallback((status?: Status) => {
        if (setOperationStatus) {
            if (status === Status.SUCCESS) {
                setOperationStatus(prev => {
                    if (prev === IOperationsStatus.ACTIVE) {
                        return IOperationsStatus.SUCCESS
                    } else {
                        return prev
                    }
                })
            } else {
                setOperationStatus(prev => {
                    if (prev === IOperationsStatus.ACTIVE) {
                        return IOperationsStatus.DONE
                    } else {
                        return prev
                    }
                })
            }
        }
        console.debug("Closing sessions start");
        stopSession(state.session, rtcConnection.current).then(() => {
            if (status === Status.CANCELED)
                dispatch(showMessage({ message: "", type: "info" }));
        });
        dispatch(removeSession());
        rtcConnection.current = null;
        console.debug("Closing sessions");
    }, [dispatch, rtcConnection, state.session, setOperationStatus]);

    const onSuccessCallback = useCallback((connection: JanusResponse | undefined) => {
        console.debug("success callback")
        const operationId = connection?.operation_id ? connection.operation_id : "";
        if (setOperationUUID) {
            setOperationUUID(operationId)
        }
    }, [setOperationUUID]);

    const setupBiometricStream = useCallback(async () => {
        if (state.session && videoRef.current && canvasRef.current && divRef.current) {
            let video = videoRef.current;
            let canvas = canvasRef.current;
            let div = divRef.current;
            let stream: MediaStream = video.srcObject as MediaStream;
            if (stream) {
                try {
                    dispatch(showMessage({ message: "Loading...", type: "info" }));
                    let janusConnection: JanusResponse | undefined;
                    rtcConnection.current = await setupWebRTC(state.session, stream);
                    await setupDataChannel(
                        rtcConnection.current,
                        state.session,
                        video,
                        undefined,
                        canvas,
                        div,
                        (message) => { dispatch(showMessage(message)) },
                        (status?: Status) => closeSession(status),
                        () => onSuccessCallback(janusConnection),
                        state.options?.livenessMode);
                    janusConnection = await connectToJanus(rtcConnection.current, state.session, state.options as FVInitParams, ModalityType.FACE_MODALITY)
                    if (!janusConnection) {
                        dispatch(showMessage({ message: "Unable to setup connection", type: "error" }));
                        closeSession();
                        return;
                    }
                } catch (err: any) {
                    closeSession();
                    let errorMessage: string = "Unable to start session";
                    console.log(err.response)
                    if (err.isAxiosError && err.response.data && err.response.data.message) {
                        errorMessage = err.response.data.message
                    }
                    console.log(errorMessage);
                    dispatch(showMessage({ message: errorMessage, type: "error" }));
                }
            } else {
                closeSession();
                dispatch(showMessage({ message: "No stream available", type: "error" }));
            }
        }
    }, [closeSession, dispatch, onSuccessCallback, state.options, state.session]);

    useEffect(() => {
        let ref: HTMLVideoElement | undefined = undefined
        if (videoRef.current)
            ref = videoRef.current

        return () => {
            if (!(ref?.srcObject as MediaStream) || ref === undefined)
                return
            (ref?.srcObject as MediaStream).getTracks().forEach((track) => {
                track.enabled=false
                track.stop()
            })
            ref.srcObject = null
        }
    }, []);

    useLayoutEffect(() => {
        if (videoRef.current && !videoRef.current.srcObject)
            setupVideo();

        if (state.session) {
            if (setOperationStatus)
                setOperationStatus(IOperationsStatus.ACTIVE)
            setupBiometricStream()
        }

        setupCanvas();
        window.addEventListener("resize", setupCanvas);
        return () => window.removeEventListener("resize", setupCanvas);
    }, [setupBiometricStream, setupCanvas, setupVideo, state.session, dispatch, setOperationStatus])

    return (
        <BiometricDiv ref={divRef}>
            <canvas ref={canvasRef} style={{ position: "absolute", zIndex: 2, top: 0, left: 0, transform: "scaleX(-1)" }} />
            <video ref={videoRef} autoPlay muted playsInline style={{ borderRadius: "5px", width: "auto", height: "100%", transform: "scaleX(-1)"}} />
            <ConfirmationDialog
                open={openCameraAccess}
                setOpen={setOpenCameraAccess}
                confirmColor="primary"
                title="Camera access"
                text="Camera access is necessary to perform this operation. Allow camera access or continue on mobile"
                cancelText="Close"
                confirmText="Continue on mobile"
                confirm={handleCameraAccess}
            />
            <OperationOnPhoneDialog
                open={openOperationOnCamera}
                setOpen={setOpenOperationOnCamera}
            />
        </BiometricDiv>
    )
})

export default BiometricFaceStream